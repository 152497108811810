import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal, Viewer } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon, ViewIcon } from 'assets/icons';
import { ReportService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class BlackList extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Imagen',
			'ID Dispositivo',
			'Nombre',
			'Verificación',
			'Fecha',
			'Acciones'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		file: null,
		visible: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Lista Negra',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.getBlackList({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.black_list.rows,
			last_page: res.black_list.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	deleteBlackList = async (e: any, image: string, identity_verification_id: number, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Eliminar la imagen de la lista negra?',async () => {
			await ReportService.admin.addBlackList({
				image,
				identity_verification_id,
				black_list_id: item?.id
			});
			this.load(true);
		});
	}

	viewVerification = (e: any, item: number) => {
		e.preventDefault();
		window.open('#/client/reports/verifications/view/' + item,'_blank');
	}

	setVisible = (value: boolean) => {
		this.setState({
			visible: value
		});
	}

	view = (url: string) => {
		this.setState({
			visible: true,
			file: url
		});
	}

	render() {
		const { visible } = this.state;

		return (
			<div id="admin-report-black-list">
				<Viewer
			      visible={ visible }
			      onClose={ () => this.setVisible(false) }
			      images={ [{ src: this.state.file }] }
			    />

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-4">
								<Input
									value={ this.state.form.search }
									name="search"
									placeholder="Buscar"
									label="Buscar por nombre o ID Dispositivo"
									className="input-table"
									onSubmit={ () => this.load() }
									onChange={ (e: any) => {
										clearTimeout(this.timer);
										this.timer = setTimeout(() => {
											this.load(true);
										},1000);
										this.change(e); 
									} } />
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Reporte de Lista Negra" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>
									{
										i.image != null ? (
											<img onClick={ () => this.view(Globals.generateS3(i.image,i.verification?.s3)) } className="image" src={ Globals.generateS3(i.image,i.verification.s3) } />
										) : (
											<>-</>
										)
									}
								</td>
								<td>{ i.mac || '-' }</td>
								<td>
									{
										(i.verification?.identity_token?.request_name || i.verification?.identity_token?.request_lastname) && (
											<p className="name">{ (i.verification?.identity_token?.request_name || '') + ' ' + (i.verification?.identity_token?.request_lastname || '') }</p>
										)
									}
									<p className="email">{ i.verification?.identity_token?.email }</p>
								</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.viewVerification(e,i.verification?.identity_token_id) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
								</td>
								<td>{ moment(i.created_at).isValid() ? moment(i.created_at).format('DD/MM/YYYY') : '-' }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.deleteBlackList(e,i.image,i.identity_verification_id,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(BlackList);