import { Api } from 'utils';

class ReportService {

	static getVerifications = async (params?: any) => Api.createResponse('client/reports/verifications',params);
	static getCases = async (params?: any) => Api.createResponse('client/reports/verifications/cases',params);
	static getBlackList = async (params?: any) => Api.createResponse('client/reports/black-list',params);
	
	static admin = {
		getVerifications: async (params?: any) => Api.createResponse('admin/reports/verifications',params),
		viewVerification: async (params?: any) => Api.createResponse('admin/reports/verifications/view',params),
		saveStatus: async (params?: any) => Api.createResponse('admin/reports/verifications/save-status',params),
		addBlackList: async (params?: any) => Api.createResponse('admin/reports/verifications/add-black-list',params),
		getBlackList: async (params?: any) => Api.createResponse('admin/reports/black-list',params),
		getClients: async (params?: any) => Api.createResponse('admin/reports/verifications/clients',params)
	}

}

export default ReportService;