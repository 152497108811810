export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		CLIENT: 3
	},
	USER: {
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		}
	},
	MODULES: {
		
	},
	IDENTITY_VERIFICATION_RESULT: {
	    YES: 1,
	    NO: 0,
	    UNFINISH: -1
	},
	S3: {
	    YES: 1,
	    NO: 0
	},
	CHANGE_VERIFICATION_TYPES: {
		DOCUMENT: 1,
		FACE: 2,
		LIVENESS: 3,
		RESULT: 4,
	},
	ORIGINS: {
		ANDROID: 1,
		IOS: 2,
		WEB: 3
	},
	IS_VEHICLE: {
	    YES: 1,
	    NO: 0
	}
}